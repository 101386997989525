
body {
    font-family: "Renault Life";
    /*height: 100%;*/
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
}

.App {
    text-align: center;
}

.app-container.container-fluid {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #000000;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App > .container {
    padding: 0;
}

.modal-open .modal {
    display: block;
}

.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.navbar-container {
    font-size: 12px;
    font-family: 'NouvelR';
    font-weight: 400;
    height: 45px;
    text-transform: uppercase;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.0784313725490196);
}

.navbar-container .nav-block {
    border-right: 2px solid #F2F2F2;
    height: 100%;
    padding-top: 15px;
}

.navbar-container .nav-block.active {
    box-shadow: inset 0 -2px 0 rgb(239, 223, 0);
}

.navbar-container .nav-block:hover {
    cursor: pointer;
}

.navbar-container .nav-block:last-child {
    border-right: none;
}

/*.navbar-container .nav-block > p {*/
/*line-height: 16px;*/
/*font-weight: 700;*/
/*color: rgb(204, 204, 204);*/
/*}*/

.navbar-container .nav-block > p.model-step,
.navbar-container .nav-block > p.city-step,
.navbar-container .nav-block > p.place-time-step,
.navbar-container .nav-block > p.booking-step {
    color: #000000;
}

.navbar-container .nav-block p span.icon {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
}

.navbar-container .nav-block p.model-step > .icon {
    position: relative;
    bottom: 5px;
    background: url("./assets/icons/iconcar_u1771_a.svg");
}

.navbar-container .nav-block p.city-step > .icon {
    position: relative;
    bottom: 7px;
    background: url("./assets/icons/iconlocation_u1769_a.svg");
}

.navbar-container .nav-block p.place-time-step > .icon {
    position: relative;
    bottom: 5px;
    background: url("./assets/icons/iconcalendar_u1767_a.svg");
}

.navbar-container .nav-block p.booking-step > .icon {
    position: relative;
    bottom: 7px;
    background: url("./assets/icons/iconedit_u1765_a.svg");
}

.form-container {
    margin-top: 0;
}

.form-container .disclaimer {
    font-size: 13px;
    text-align: left;
    padding-top: 10px;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    text-align: center !important;
    border-top: none !important;
}

/*!*MODAL*!*/

.fade.modal.show {
    opacity: 1;
}

.modal-backdrop.show {
    opacity: .5 !important;
}

.modal-dialog.modal-40w {
    text-align: center;
}

/*temp CSS*/
a.btn.call-me-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}


/*.form-control.has-error {*/
    /*border: 1px solid red;*/
/*}*/

.form-control.has-error:focus {
    border-color: red;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}


.empty-day {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
}

.empty-time-slots {
    display: block;
    position: relative;
    top: 10px;
    font-size: 14px;
    margin: 0 auto 20px;
}

.time-slots {
    display: none;
}

.navbar-container {
    /*background-color: #F2F2F2;*/
}

.marker {
    position: absolute;
    height: 48px;
    width: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: ease-in-out .2s;
}
  
.marker:hover {
    transform: translate(-50%, -50%) scale(1.3);
}
  
.markerLabel {
    position: relative;
    padding: 7px;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/*Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .navbar-container {
        height: 80px;
        text-transform: uppercase;
        /*padding: 0 10.6%;*/
        border-left: 1px solid #F2F2F2;
        border-right: 1px solid #F2F2F2;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.0784313725490196);
    }

    .navbar-container .nav-block {
        /*border-left: 2px solid #F2F2F2;*/
        height: 100%;
        text-align: left;
        padding-top: 20px;
    }

    .navbar-container .nav-block.active {
        box-shadow: inset 0 -2px 0 rgb(239, 223, 0);
    }

    .navbar-container .nav-block:hover {
        cursor: pointer;
    }

    .navbar-container .nav-block:last-child {
        /*border-right: 2px solid #F2F2F2;*/
    }

    .navbar-container .nav-block > p {
        line-height: 16px;
        font-weight: 700;
        color: rgb(204, 204, 204);
    }

    /*.navbar-container .nav-block > p.model-step,*/
    /*.navbar-container .nav-block > p.city-step,*/
    /*.navbar-container .nav-block > p.place-time-step,*/
    /*.navbar-container .nav-block > p.booking-step {*/
    /*    color: rgb(153, 153, 153);*/
    /*}*/

    .navbar-container .nav-block p span.icon {
        display: block;
        width: 24px;
        height: 24px;
        float: right;
    }

    .navbar-container .nav-block p.model-step > .icon {
        position: relative;
        bottom: 5px;
        background: url("./assets/icons/iconcar_u1771_a.svg");
    }

    .navbar-container .nav-block p.city-step > .icon {
        position: relative;
        bottom: 7px;
        background: url("./assets/icons/iconlocation_u1769_a.svg");
    }

    .navbar-container .nav-block p.place-time-step > .icon {
        position: relative;
        bottom: 5px;
        background: url("./assets/icons/iconcalendar_u1767_a.svg");
    }

    .navbar-container .nav-block p.booking-step > .icon {
        position: relative;
        bottom: 7px;
        background: url("./assets/icons/iconedit_u1765_a.svg");
    }

    .form-container .disclaimer {
        font-size: 12px;
        text-align: left;
    }

    .modal-dialog.modal-40w {
        width: 40%;
    }

    .empty-time-slots {
        top: 25px;
        font-size: 18px;
        margin: 0 auto;
    }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}