@font-face {
  font-family: RenaultLife;
  font-style: normal;
  src: url('../fonts/RenaultLife-Regular.ttf');
  src: url('../fonts/RenaultLife-Regular.ttf?#iefix') format('truetype'),
      url('../fonts/RenaultLife-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RenaultLife;
  font-style: italic;
  src: url('../fonts/RenaultLife-Italic.ttf');
  src: url('../fonts/RenaultLife-Italic.ttf?#iefix') format('truetype'),
      url('../fonts/RenaultLife-Italic.ttf') format('truetype');
}

@font-face {
  font-family: RenaultLife;
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/RenaultLife-Bold.ttf');
  src: url('../fonts/RenaultLife-Bold.ttf?#iefix') format('truetype'),
      url('../fonts/RenaultLife-Bold.ttf') format('truetype');
}


@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Light.woff2') format('woff2'),
  url('../fonts/NouvelR-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Book.woff2') format('woff2'),
  url('../fonts/NouvelR-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Regular.woff2') format('woff2'),
  url('../fonts/NouvelR-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Semibold.woff2') format('woff2'),
  url('../fonts/NouvelR-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Bold.woff2') format('woff2'),
  url('../fonts/NouvelR-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url('../fonts/NouvelR-Extrabold.woff2') format('woff2'),
  url('../fonts/NouvelR-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR-Variable';
  src:	url('../fonts/NouvelR-Variable.woff2') format('woff2'),
  url('../fonts/NouvelR-Variable.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

